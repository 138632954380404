<!--
 * @Author       : JiangChao
 * @Date         : 2022-02-19 11:51:03
 * @LastEditors  : JiangChao
 * @LastEditTime : 2024-04-15 15:39:24
 * @Description  : 我的
-->
<template>
    <div>
        <div class="container-pa16">
            <br />
            <br />
            <br />
            <br />
            <div class="logo" @click="goPersonalInfo">
                <van-image
                    class="fillet-img"
                    :src="this.avatar"
                    :error-icon="
                        require('../assets/imgs/png/icon_register_logo.png')
                    "
                />
                <h3>{{ userMobile }}</h3>
            </div>
            <br />
            <br />
            <br />
            <br />
            <div class="buttons">
                <div
                    class="button-col-3"
                    v-for="item in buttons"
                    :key="item.key"
                >
                    <img
                        @click="$_href(item.href)"
                        :src="getImageUrl(item.image)"
                    />
                    <br />
                    <span class="text-875 text-b">{{ item.name }}</span>
                </div>
            </div>
            <br />
            <br />
            <div class="bottom-name">
                <img :src="require('@/assets/imgs/svg/0527-c.svg')" />
            </div>
        </div>
        <div class="background-decoration-bottom"></div>
    </div>
</template>
<script>
import { handleErr } from "@/helpers";
export default {
    data() {
        return {
            userMobile: "",
            avatar: "",
            tzUrl: "https://www.baidu.com",
            buttons: [
                {
                    image: "icon_user_qianbao.png",
                    name: "我的錢包",
                    href: "DepositMethod",
                },
                { image: "0527-e.svg", name: "聯絡我們", href: "contact" },
                {
                    image: "0527-f.svg",
                    name: "使用教學",
                    href: "https://youtu.be/eK41TNdnuhM?si=QQpgWmzbUB2mI8lg",
                },
                { image: "0527-g.svg", name: "用戶協議", href: "terms" },
                { image: "0527-j.svg", name: "優惠券", href: "coupon" },
                { image: "0527-i.svg", name: "租借紀錄", href: "records" },
                { image: "0527-h.svg", name: "常見問題", href: "questions" },
                { image: "k-0712.png", name: "「Joinit 會員」", href: "JoinDid" },
            ],
            money: 0,
            bindCardStatus: false,
            expireDate: "",
            subscribe: "",
        };
    },
    methods: {
        getImageUrl(image) {
            return require(`@/assets/imgs/svg/${image}`);
        },
        async $_href(page) {
            if (!page) return;
            if (page.indexOf("https") !== -1) {
                window.location.href = page;
            } else {
                console.log("page: ", page);
                //點擊我的錢包
                if (page === "DepositMethod") {
                    if (this.money > 0) {
                     //说明充值了金额，跳转到退押金界面
                        this.$router.push("RefundDeposit");
                    } else if (this.bindCardStatus) {
                     //绑卡了，跳转到取消绑卡
                        this.$router.push("CancelBind");
                    } else if (this.expireDate) {
                        //有免费时间，但是没有订阅，说明是通过优惠券或者老板后台设置该帐号有免费日期，直接跳转到显示免费日期的页面，
                        //有免费时间，但是在订阅状态，说明是通订阅状态，直接跳转到显示免费日期的页面，暂时没接订阅，不给解除订阅。后面再加
                        //跳转到订阅中，无解除绑定按钮
                        if (this.subscribe) {
                            this.$router.push("Subscription");
                        } else {
                            this.$router.push("Subscription");
                        }
                   }else{
                        //交钱
                         this.$router.push("DepositMethod");
                    }
                } else if (page === "JoinDid") {
                    const res = await this.$axios.get("joindid/geturl");
                          if (res.data.success) {
                              window.location.href = res.data.result;
                          }
                }else {
                    this.$router.push(page);
                }
            }
        },
        goPersonalInfo() {
            this.$router.push("PersonalInfo");
        },
        async getUserInfo() {
            try {
                const res = await this.$axios.get("customer/user-info");
                                console.log("res: ", res);

                if (res.data.success) {
                    const subscribe =
                        res.data.result.subscribe == true ? "1" : "0";
                    const money = res.data.result.money;
                    const startExpireDate = res.data.result.startExpireDate;
                    const freeHour = res.data.result.freeHour;
                    const bindCardStatus =
                        res.data.result.bindCardStatus == true ? "1" : "0";
                    const expireDate = res.data.result.expireDate;
                    const avatar = res.data.result.avatar;
                    const mobile = res.data.result.mobile;

                    this.avatar =
                        "https://s3lvelect.s3.ap-east-1.amazonaws.com/" +
                        avatar;
                    console.log("avatar: ", avatar);
                    this.userMobile =mobile;
                    localStorage.setItem("user_mobile", mobile);
                    localStorage.setItem("avatar", avatar);
                    localStorage.setItem("subscribe", subscribe);
                    console.log("subscribe: ", subscribe);
                    localStorage.setItem("money", money);
                    localStorage.setItem("startExpireDate", startExpireDate);
                    localStorage.setItem("freeHour", freeHour);
                    localStorage.setItem("bindCardStatus", bindCardStatus);
                    console.log("bindCardStatus: ", bindCardStatus);
                    localStorage.setItem("expireDate", expireDate);
                    this.bindCardStatus =
                        Number(localStorage.getItem("bindCardStatus")) == "1"
                            ? true
                            : false;
                    this.subscribe =
                        Number(localStorage.getItem("subscribe")) == "1"
                            ? true
                            : false;
                    this.money = money;
                    this.expireDate = expireDate;
                    console.log("this.money: ", this.money);
                }
            } catch (error) {
                console.log(error, "store error");
                handleErr({ ...error, app: this.$parent });
            }
        },
    },
    mounted() {
        const token = window.$cookies.get("token");
        if (token) {
            this.$axios.defaults.headers.common["token"] = token;
            this.$axios.defaults.headers.common["appType"] = "PHONE";
        }
        // this.userMobile = localStorage.getItem("user_mobile");
        this.getUserInfo();
    },
};
</script>
